import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/image/timetable/no_ai_material.png'


const _hoisted_1 = { class: "blank" }
const _hoisted_2 = { class: "imgs_content" }
const _hoisted_3 = { class: "imgs_content-bg" }
const _hoisted_4 = { style: {"position":"relative"} }
const _hoisted_5 = { class: "swiper" }
const _hoisted_6 = { class: "swiper-wrapper" }
const _hoisted_7 = { class: "swiper-item_img" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { style: {"height":"650px"} }
const _hoisted_10 = { class: "detail-content" }
const _hoisted_11 = { style: {"position":"relative"} }
const _hoisted_12 = ["src"]

import CloseIcon from "@/components/system/CloseIcon.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import {computed, onBeforeMount, onMounted, reactive, ref} from "vue";
import GotoLesson from "@/components/timetable/GotoLesson.vue";
import {Button as AButton} from 'ant-design-vue'
import Swiper, {Grid} from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/modules/grid/grid.min.css'
import service from "@/utils/axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialAIMt',
  setup(__props) {


Swiper.use([Grid])
let showBlank = ref(false);
let showDetail = ref(false);
let opacity = ref(1);
let showDetailIndex = ref(0)
let aiImgActive = ref(true)
let dataRef = reactive({
  swiperImgs: []
})
let swiperRef;
let detailImg = computed(() => {
  if (dataRef.swiperImgs[showDetailIndex.value]) {
    return dataRef.swiperImgs[showDetailIndex.value]
  } else {
    return {
      genImg: '',
      orgImg: '',
      sceneDes: ''
    }
  }
})

const init = () => {
  swiperRef = new Swiper('.swiper', {
    autoplay: false,//可选选项，自动滑动
    slidesPerView: 2,
    grid: {
      fill: 'column',
      rows: 2,
    },
    on: {
      slideChange: () => {
        setNavStyle()
      },
      click: (e) => {
        showDetailIndex.value = e.clickedIndex;
        showDetail.value = true;
      },
    }
  })
}

const onSwiperPageClick = (goAfter) => {
  if (goAfter && !swiperRef.isEnd) {
    swiperRef.slideNext();
  }
  if (!goAfter && !swiperRef.isBeginning) {
    swiperRef.slidePrev();
  }
  setNavStyle()
}

const setNavStyle = () => {
  opacity.value = 3;
  if (swiperRef.isEnd) {
    opacity.value = 2;
  }
  if (swiperRef.isBeginning) {
    opacity.value = 1;
  }
}
const onAIActiveChange = (isAi) => {
  aiImgActive.value = isAi;
}

const onCloseDetail = () => {
  aiImgActive.value = true;
  showDetail.value = false;
}

const getImgList = () => {
  service.post('/dwart/an_teaching/aibox/getUserHistoryDrawList', {
    pageNum: 1,
    pageSize: 100,
  }).then(res => {
    if (res?.list.length === 0){
      showBlank.value = true;
      return
    }
    dataRef.swiperImgs = res.list;
  })
}

onBeforeMount(() => {
  getImgList()
})

onMounted(() => {
  init()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(TimetableTitle, null, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("ai素材")
        ])),
        _: 1
      }),
      _createVNode(CloseIcon)
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn animate__faster",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            draggable: "false"
          }, null, -1)
        ]), 512), [
          [_vShow, _unref(showBlank)]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn animate__faster",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRef).swiperImgs, (i, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "swiper-slide swiper-item",
                      key: key
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", null, [
                          _createElementVNode("img", {
                            src: i.genImg,
                            alt: ""
                          }, null, 8, _hoisted_8)
                        ]),
                        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "img-cover" }, null, -1))
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["swiper-button-custom-prev", {'opacity': _unref(opacity) === 1}]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (onSwiperPageClick(false)))
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["swiper-button-custom-next", {'opacity': _unref(opacity) === 2}]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (onSwiperPageClick(true)))
              }, null, 2)
            ], 512), [
              [_vShow, !_unref(showDetail)]
            ]),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__zoomIn animate__faster",
              "leave-active-class": "animate__animated animate__zoomOut animate__faster"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["detail-btn", {'active': _unref(aiImgActive)}]),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (onAIActiveChange(true)))
                      }, "ai效果图 ", 2),
                      _withDirectives(_createElementVNode("div", {
                        class: _normalizeClass(["detail-btn", {'active': !_unref(aiImgActive)}]),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (onAIActiveChange(false)))
                      }, "原图 ", 2), [
                        [_vShow, _unref(detailImg).orgImg]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("img", {
                        src: _unref(aiImgActive)?_unref(detailImg).genImg: _unref(detailImg).orgImg,
                        alt: "",
                        style: {"width":"959px","height":"650px","object-fit":"contain","pointer-events":"none"}
                      }, null, 8, _hoisted_12),
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "img-cover" }, null, -1))
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", {
                        class: "detail-close",
                        onClick: onCloseDetail
                      })
                    ])
                  ])
                ], 512), [
                  [_vShow, _unref(showDetail)]
                ])
              ]),
              _: 1
            })
          ])
        ], 512), [
          [_vShow, !_unref(showBlank)]
        ])
      ]),
      _: 1
    }),
    _createVNode(GotoLesson)
  ]))
}
}

})